
import { computed, ref, onMounted, ComputedRef, watch, Ref } from 'vue';

import useTextUtils from '@/composables/useTextUtils';
import FacilityData, { bookingTypes } from '@/models/sharedFacilities/FacilityData';
import dayjs, { Dayjs } from 'dayjs';
import endpoints from '@/api/endpoints';
import axios from 'axios';
import { useQuasar } from 'quasar';
import { useStore } from 'vuex';
import Actions from '@/store/actions';
import Subtitle1 from '@/components/typography/Subtitle1.vue';
import BrandButton from '@/components/common/BrandButton.vue';
import { useRouter } from 'vue-router';
import Separator from '@/components/common/Separator.vue';

type bookingOption = {
	time: string,
	status: string,
}

type bookingInfo = {
	totalPrice: number,
	startTime: string,
	endTime: string
}
   
export default {
  components: { Subtitle1, BrandButton, Separator },
	props: {
		facility: {
			type: FacilityData,
			required: true
		}
	},
	setup(props){
		const { filterText } = useTextUtils();
		const $q = useQuasar();
		const store = useStore();
		const router = useRouter();

		const date = ref(null)

		const selectableOptions: Ref<bookingOption[]>  = ref([]);
		const startTime: Ref<Dayjs | null> = ref(null);
		const endTime: Ref<Dayjs | null> = ref(null);
		const dayIsBooked = ref(false);

		const showSuccessInfo: Ref<boolean> = ref(false)

		const bookingType: ComputedRef<string> = computed(() => props.facility.bookingType);
		const filteredName: ComputedRef<string> = computed(() => props.facility?.name ? filterText(props.facility.name) : '');
		const duration: ComputedRef<number> = computed(() => props.facility?.block?.duration ?? 60);
		const maxBookableBlocks: ComputedRef<number> = computed(() => props.facility?.block?.maxBookableBlocks ?? 1);
		const price: ComputedRef<number> = computed(() => startTime.value && endTime.value ? Math.floor(endTime.value.diff(startTime.value, 'minutes') / duration.value ) * Number(props.facility.price) : 0)
		const canBeBooked: ComputedRef<boolean> = computed(() => Boolean(startTime.value && endTime.value));

		function checkIfBookable(mask: any, index: number) {
			if(index === 0){
				return Boolean(Number(mask[6]))
			}
			return typeof mask !== 'undefined' ?  Boolean(Number(mask[index])) : false;
		}

		function isSelected(selectedTimeObj){
			if(!startTime.value || !endTime.value){
				return false;
			}

			return (selectedTimeObj.isSame(startTime.value) || selectedTimeObj.isAfter(startTime.value))
						&& 
					(selectedTimeObj.add(duration.value, 'minutes').isSame(endTime.value) || selectedTimeObj.add(duration.value, 'minutes').isBefore(endTime.value))
		}

		function onTimeSelected(dateObject) {
			
			let newStartTimeObject = startTime.value;
			let newEndTimeObject = endTime.value;

			//Clicks the only block selected, resets start and end time
			if (dateObject.isSame(startTime.value) && dateObject.add(duration.value, 'minutes').isSame(endTime.value)){
				newStartTimeObject = null;
				newEndTimeObject = null;

			// If new block is in front of the selected time period, it moves the starttime one block behind
			} else if (dateObject.add(duration.value, 'minutes').isSame(startTime.value)){
				newStartTimeObject = dateObject;

			// If new block is right after the selected time period, it moves the endtime one block forward
			} else if (dateObject.isSame(endTime.value)){
				newEndTimeObject = dateObject.add(duration.value, 'minutes');

			// If new block is the first block in the selected time periode, it moves the starttime one block forward
			} else if (dateObject.isSame(startTime.value)) {
				newStartTimeObject = dateObject.add(duration.value, 'minutes');

			// If new block is the last block in the selected time periode, it moves the endtime one block behind
			} else if (dateObject.add(duration.value, 'minutes').isSame(endTime.value)) {
				newEndTimeObject = dateObject;

			} else {
				newStartTimeObject = dateObject;
				newEndTimeObject = dateObject.add(duration.value, 'minutes');
			}

			if(newStartTimeObject && newEndTimeObject){
				const newDuration = newEndTimeObject.diff(newStartTimeObject, 'minutes');
				if(newDuration > (duration.value * maxBookableBlocks.value)){
					 $q.notify({
						message: `Maximum bookable time span is ${duration.value * maxBookableBlocks.value} minutes!`,
						type: 'warning',
						position: 'top',
					});
					return;
				}
			}

			startTime.value = newStartTimeObject ? newStartTimeObject : null;
			endTime.value = newEndTimeObject ? newEndTimeObject : null;
		}


		const isDayBookable = (day) => {
			const dateObject = dayjs(day);
			if(dateObject.isBefore(dayjs().format("YYYY/MM/DD"))){
				return false;
			} else if ((bookingType.value === bookingTypes.DAILY_BOOKING) && dateObject.isSame(dayjs().format("YYYY/MM/DD"))){
				return false
			}

			const weekIndex = dateObject.day();
			return checkIfBookable(props.facility.availabilityMask, weekIndex);
		}

		async function fetchAvailibility(date: string) {
			const response = await axios.get(endpoints.Booking.FACILITY_UNAVAILABILITY(props.facility.id, date))

			const options: bookingOption[] = response.data.reduce(function(filtered, option) {

				const dateTimeObject = dayjs.utc(option.block);
				const currentDateTimeObject = dayjs().utc(true);

				if (option.status !== 'disabled' && dateTimeObject.isAfter(currentDateTimeObject)) {
					filtered.push({
						time: dateTimeObject,
						status: option.status
					});
				}
				return filtered;
			}, []);

			if(bookingType.value === bookingTypes.DAILY_BOOKING){
				if(options.map(item => item.status).includes('booked')){
					dayIsBooked.value = true;
				} else {
					const dayDateObject = dayjs.utc(options[0].time)
					startTime.value = dayDateObject.startOf('d');
					endTime.value = dayDateObject.endOf('d');
				}
			} else {
				selectableOptions.value = options;
			}
		}

		function getTimeStampFromObj(timeObj){
			return timeObj.format('LT')
		}

		function getLocalDateFromString(time: string){
			return dayjs.utc(time).format('LL')
		}

		//TODO must check mask what day is the first day that is available
		function getFirstAvailableDay(){
			//Cant book the current day if booking type is daily
			if(bookingType.value === bookingTypes.DAILY_BOOKING){
				return dayjs().add(1, 'day').format('YYYY/MM/DD');
			} else {
				return dayjs().format('YYYY/MM/DD');
			}
		}

		function isBooked(status){
			return status === 'booked';
		}

		function onBook() {
			const startTimeString = startTime.value.format();
			const endTimeString = endTime.value.subtract(1, 'second').format();
			store.dispatch(Actions.SUBMIT_BOOKING, {
				date: date.value, 
				startTime: startTimeString,
				endTime: endTimeString, 
				facilityId: props.facility.id
			}).then((response) => {
				if(response && response.status === 201){
					showSuccessInfo.value = true;
				}
			})
		}

		function redirectToBookings() {
			router.push({name: 'BookingList'});
		}

		watch(() => date.value, (newDate) => {
			startTime.value = null;
			endTime.value = null;
			dayIsBooked.value = false;
			fetchAvailibility(newDate);
		})

		onMounted(() => {
			date.value = getFirstAvailableDay();
		})

		return {
			filteredName,
			date,
			isDayBookable,
			selectableOptions,
			onTimeSelected,
			startTime,
			endTime,
			isSelected,
			bookingType,
			bookingTypes,
			getTimeStampFromObj,
			dayIsBooked,
			getLocalDateFromString,
			isBooked,
			canBeBooked,
			onBook,
			price,
			showSuccessInfo,
			redirectToBookings
		}
	}
	
}
