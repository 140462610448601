import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_2 = _resolveComponent("heading-2")!
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_BookingItem = _resolveComponent("BookingItem")!
  const _component_separator = _resolveComponent("separator")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_base_card_section = _resolveComponent("base-card-section")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_with_right_side_bar = _resolveComponent("with-right-side-bar")!

  return (_openBlock(), _createBlock(_component_with_right_side_bar, null, {
    default: _withCtx(() => [
      _createVNode(_component_base_card, { class: "bookings-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_base_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_heading_2, { class: "bookings-card__header" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('shared-facilities.bookings', 'Bookings')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_heading_3, { class: "bookings-card__section-header" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.upcoming', 'Upcoming')), 1)
                ]),
                _: 1
              }),
              ($setup.upcomingBookings.length > 0)
                ? (_openBlock(), _createBlock(_component_q_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.upcomingBookings, (booking, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          _createVNode(_component_BookingItem, { booking: booking }, null, 8, ["booking"]),
                          ($setup.upcomingBookings.length > 0 && index + 1 !== $setup.upcomingBookings.length)
                            ? (_openBlock(), _createBlock(_component_separator, {
                                key: 0,
                                class: "bookings-card__separator"
                              }))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_subtitle_1, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('bookings.no-upcoming-bookings', 'No upcoming bookings')), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          }),
          ($setup.previousBookings)
            ? (_openBlock(), _createBlock(_component_base_card_section, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_heading_3, { class: "bookings-card__section-header" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.previous', 'Previous')), 1)
                    ]),
                    _: 1
                  }),
                  ($setup.previousBookings.length > 0)
                    ? (_openBlock(), _createBlock(_component_q_list, { key: 0 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.previousBookings, (booking, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              _createVNode(_component_BookingItem, { booking: booking }, null, 8, ["booking"]),
                              ($setup.previousBookings.length > 0 && index + 1 !== $setup.previousBookings.length)
                                ? (_openBlock(), _createBlock(_component_separator, {
                                    key: 0,
                                    class: "bookings-card__separator"
                                  }))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_subtitle_1, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('bookings.no-previous-bookings', 'No previous bookings')), 1)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}