
import BookingMenuItem from '@/views/Bookings/components/BookingMenuItem.vue';
import BookingData from '@/models/BookingData';
import useGlobalModal from '@/composables/useGlobalModal';
import { useStore } from 'vuex';
import { computed, KeepAlive } from 'vue';

export default {
	components: {
		BookingMenuItem,
		KeepAlive,
	},
	setup() {
		const store = useStore();
		const { navigateToModal } = useGlobalModal();

		const bookings = computed(() => store.getters['SharedFacilities/getUserFacilitiesBookings']);
	
		const lastActiveBookings = computed(() => {
			return bookings.value
				.filter(
					(booking: BookingData) =>
						booking.isActiveBooking && !booking.deletedAt
				)
				.sort((a, b) => b.startTimeTimestamp - a.startTimeTimestamp)
				.splice(0, 3);
		});

		function showBookingModal(bookingId) {
			navigateToModal(bookingId, 'bookingDetail');
		}

		return {
			lastActiveBookings,
			showBookingModal,
		};
	},
};
