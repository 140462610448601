import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f969b0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upcoming-booking" }
const _hoisted_2 = { class: "upcoming-booking__header" }
const _hoisted_3 = {
  key: 0,
  class: "upcoming-booking__list"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BookingMenuItem = _resolveComponent("BookingMenuItem")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_KeepAlive, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_heading_3, { class: "upcoming-booking__header__title" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.my-bookings', 'My Bookings')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: { name: 'BookingList' },
          class: "link-style"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_heading_3, { class: "upcoming-booking__header__see-more" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.see-more', 'See more')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      ($setup.lastActiveBookings.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.lastActiveBookings, (booking, index) => {
              return (_openBlock(), _createBlock(_component_BookingMenuItem, {
                booking: booking,
                key: index,
                "data-booking-index": index,
                onClick: ($event: any) => ($setup.showBookingModal(booking.id))
              }, null, 8, ["booking", "data-booking-index", "onClick"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_base_card, { class: "no-bookings-card" }, {
              default: _withCtx(() => [
                _createVNode(_component_subtitle_1, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('bookings.no-current-bookings', 'No current bookings')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
    ])
  ], 1024))
}