
import { useStore } from 'vuex';
import { computed, ComputedRef, ref, onMounted } from 'vue';
import Actions from '@/store/actions';
import Mutations from '@/store/mutations';
import FacilityPhotoData from '@/models/sharedFacilities/FacilityPhotoData';
import { useRouter } from 'vue-router';
import BookingDetailsImage from '@/views/Bookings/components/BookingDetailsImage.vue';
import CancelBooking from '@/views/Bookings/modals/CancelBooking.vue';

export default {
	components: {
		BookingDetailsImage,
		CancelBooking,
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const confirmCancel = ref(false);

		const booking = computed(() => {
			return store.getters['SharedFacilities/getUserFacilitiesBookings'].find(
				(booking: any) =>
					booking.id == router.currentRoute.value.query.bookingId
			);
		});

		const facility = computed(() => {
			return booking.value && booking.value !== 'undefined'
				? store.getters['SharedFacilities/getUserSharedFacilities'].find(
					(facility: any) => facility.id === booking.value.facilityId
				  )
				: null;
		});

		const facilityPhotos: ComputedRef<FacilityPhotoData[]> = computed(() => {
			return store.state.SharedFacilities.sharedFacilityPhotos;
		});

		onMounted(() => {
			store.commit(Mutations.RESET_SHARED_FACILITIES_PHOTOS);

			if (typeof facility.value !== 'undefined' && facility.value) {
				store.dispatch(
					Actions.FETCH_SHARED_FACILITIES_PHOTOS,
					facility.value.id
				);
			}
		});

		return {
			booking,
			facilityPhotos,
			facility,
			confirmCancel,
		};
	},
};
