export const Connection = { 
	port: '',
	apiUrl: '',
	baseUrl: '',
	echoUrl: '',
	socketEnabled: true,
	clockwork: false,
	clockworkSecret: '',

	setUrls() {
		switch (location.hostname) {
		case 'hococo-ui.local':
			this.baseUrl = 'http://hococo.local';
			this.echoUrl = 'http://hococo.local:6001';
			break;

		case 'app.hococo.io':
			this.baseUrl = 'https://api.hococo.io';
			this.echoUrl = 'https://echo.hococo.io';
			break;

		case 'app-staging.hococo.io':
			this.baseUrl = 'https://api-staging.hococo.io';
			this.echoUrl = 'https://echo-staging.hococo.io';
			break;

		case 'app-test.hococo.io':
			this.baseUrl = 'https://api-test.hococo.io';
			this.echoUrl = 'https://echo-test.hococo.io';
			break;                                    

		case 'app-develop.hococo.io':
			this.baseUrl = 'https://api-dev.hococo.io';
			this.echoUrl = 'https://echo-dev.hococo.io';
			break;                

		case 'admin-local.hococo.io':
		default:                
			this.baseUrl = 'https://api-local.hococo.io';
			this.echoUrl = 'https://echo-local.hococo.io';
			break;
		}

		this.apiUrl = this.baseUrl + this.port;
	},
	getApiUrl() {
		if (this.apiUrl === '') {
			this.setUrls();
		}

		return this.apiUrl;
	},
	getEchoUrl() {
		if (this.echoUrl === '') {
			this.setUrls();
		}

		return this.echoUrl;
	},
};
