
import BookingData from '@/models/BookingData';
import useGlobalModal from '@/composables/useGlobalModal';
import { ref } from 'vue';
import CancelBooking from '@/views/Bookings/modals/CancelBooking.vue';
import dayjs from 'dayjs';
import BookingItemImage from './BookingItemImage.vue';

export default {
	props: {
		booking: {
			type: BookingData,
			required: true,
		},
	},
	components: {
		CancelBooking,
		BookingItemImage,
	},
	setup(props) {
		const confirmCancel = ref(false);
		const { navigateToModal } = useGlobalModal();
		const showCancelModal = ref(false);

		function redirectToBookingDetail() {
			navigateToModal(props.booking.id.toString(), 'bookingDetail');
		}

		function getLocalTime(date: string) {
			return dayjs.utc(date).format('LT');
		}

		return {
			showCancelModal,
			redirectToBookingDetail,
			confirmCancel,
			getLocalTime,
		};
	},
};
